import React from "react"

import { LocalizedLink as Link } from "gatsby-theme-i18n"
import { useIntl } from "react-intl"

import LoginRequired from "./login_required"

const ExchangeNavigation = ({ location }) => {
  const page = location.pathname.split("/").pop()
  return (
    <ul className="nav nav-pills mb-2">
      <NavLink page={page} to="exchange" title="Welcome" />
      <NavLink page={page} to="exchange_buy" title="Buy" disabled={true} />
      <NavLink page={page} to="exchange_sell" title="Sell" disabled={true} />
      <NavLink page={page} to="exchange_swap" title="Swap" />
      <NavLink page={page} to="exchange_deposit" title="Deposit" login={true} disabled={true} />
      <NavLink page={page} to="exchange_order_history" title="Order History" />
    </ul>
  )
}

const NavLink = ({ page, to, title, login, disabled }) => {
  const intl = useIntl()
  const titleTranslated = intl.formatMessage({ id: title })
  const span = <span>{titleTranslated}</span>
  const loginRequired = <LoginRequired>{titleTranslated}</LoginRequired>
  if (disabled) {
    return (
      <div
        className={"nav-link " + (page == to ? "active" : "") + " mr-1 mb-1 disabled"}
      >
        {!login ? span : loginRequired}
      </div>
    )
  } else {
    return (
      <Link
        to={"/" + to}
        className={"nav-link " + (page == to ? "active" : "") + " mr-1 mb-1"}
      >
        {!login ? span : loginRequired}
      </Link>
    )
  }
}

export default ExchangeNavigation
