import React from "react"

import Card from "react-bootstrap/Card"
import { useIntl } from "react-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ExchangeNavigation from "../components/exchange_navigation"
import CardDeck from "../components/card_deck"
import FormattedMarkdown from "../components/formatted_markdown"

import iconfinder_28_3319616 from "../images/iconfinder_28_3319616.png"
import iconfinder_13_3319631 from "../images/iconfinder_13_3319631.png"
import iconfinder_6_3319634 from "../images/iconfinder_6_3319634.png"

const ExchangePage = ({ location }) => {
  const intl = useIntl()
  const pageTitle = intl.formatMessage({ id: "Exchange" })
  return (
    <Layout pageTitle={pageTitle} location={location}>
      <SEO title={pageTitle} />
      <ExchangeNavigation location={location} />
      <CardDeck>
        <ExchangeCard id="Exchange" img={iconfinder_28_3319616} />
        <ExchangeCard id="Rate" img={iconfinder_13_3319631} />
        <ExchangeCard id="Fees" img={iconfinder_6_3319634} />
      </CardDeck>
    </Layout>
  )
}

const ExchangeCard = props => {
  const intl = useIntl()
  return (
    <Card>
      <Card.Img src={props.img} />
      <Card.Body>
        <Card.Title>{intl.formatMessage({ id: props.id })}</Card.Title>
        <Card.Text>
          <FormattedMarkdown id={"Exchange." + props.id} />
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

export default ExchangePage
